import React, { forwardRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import Icon from "../../lib/icon";
import ErrorMessage from '../error-message';
import moment from 'moment';
import nb from "date-fns/locale/nb";
import { Collapsible } from 'grommet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import {Box, Button, ButtonGroup, Checkbox, Container, Flex, Input, Txt} from "rendition";
import Tooltip from "../../lib/tooltip";

registerLocale("nb", nb);

const roundSeriesFrom = from => {
    if (!from) {
        return undefined;
    }
    const m = moment(from);
    return m.clone()
        .subtract(m.isoWeekday() - 1, 'd')
        .format("YYYY-MM-DD");
}

const roundSeriesUntil = (from, until) => {
    if (!from || !until) {
        return undefined;
    }
    const roundedUpWeeks = Math.ceil(moment(until).diff(moment(from), 'd') / 7);
    return moment(from)
        .add(roundedUpWeeks > 0 ? roundedUpWeeks : 1, 'w')
        .subtract(1, 'd')
        .format("YYYY-MM-DD");
}

const roundSeriesRange = pattern => {
    const [from, until] = [pattern.series.from, pattern.series.until];
    const roundedFrom = roundSeriesFrom(from);
    return [roundedFrom, roundSeriesUntil(roundedFrom, until)];
}

const safeDatePickerOnChangeWrapper
    = onChange => date => {
        if (!date) {
            onChange(undefined);
            return;
        }
        const utc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        onChange(utc.toISOString().split("T")[0])
    }

export const CustomDateInput = forwardRef(({ value, onClick }, ref) => <Flex
    onClick={onClick}
    style={{
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "space-between",
        width: "100%",
    }}>
    <Txt onClick={onClick} ref={ref}>{value ? value : "dd.mm.åååå"}</Txt>
    <Icon name="date" f="right" />
</Flex>);

const DateInput = ({ value, onChange, ...props }) =>
    <DatePicker
        showWeekNumbers
        locale="nb"
        dateFormat="dd.MM.yyyy"
        placeholderText="d.m.yyyy"
        selected={value ? new Date(value) : undefined}
        onChange={safeDatePickerOnChangeWrapper(onChange)}
        customInput={<CustomDateInput />}
        {...props}
    >
        <Box style={{ margin: "2em" }}>
            <Button plain onClick={() => onChange("")}>
                fjern
            </Button>
        </Box>
    </DatePicker>

const DateSelection = ({ value, onChange, onClose, ...props }) =>
    <Container style={{
        border: value ? "1px solid #ccc" : "1px solid red",
        borderRadius: "20px",
        margin: "0",
        fontSize: "14px",
        height: "38px",
        width: "160px"
    }}>
        <Flex style={{ "padding": "8px 0px 5px 0px" }}>
            <DateInput value={value} onChange={onChange} {...props} />
            <Button plain onClick={onClose} style={{ "marginLeft": "1em" }}><FontAwesomeIcon icon={faTimes} /></Button>
        </Flex>
    </Container>

export {roundSeriesRange };
export default ({pattern, setPattern, error, readOnly }) => {
    const [selectedDate, setSelectedDate] = useState(undefined);

    const addDay = (date = "") => {
        setPattern({ ...pattern, includeDays: [...pattern.includeDays, date] });
    }

    const updateDay = (i, date) => {
        const newDays = [...pattern.includeDays];
        newDays[i] = date;
        setPattern({ ...pattern, includeDays: newDays });
    }

    const removeDay = (i) => {
        const removedDay = pattern.includeDays[i];

        const newDays = [...pattern.includeDays];
        newDays.splice(i, 1);
        setPattern({ ...pattern, includeDays: newDays });

        if (removedDay === selectedDate.toISOString().split("T")[0]) {
            setSelectedDate(undefined);
        }
    }

    const toggleDay = (date) => {
        if (pattern.includeDays.some(d => d === date)) {
            setPattern({ ...pattern, days: pattern.includeDays.filter(d => d !== date) });
            setSelectedDate(undefined);
        } else {
            addDay(date);
            setSelectedDate(new Date(date));
        }
    }

    const toggleShift = (shift) => {
        const newState = { ...pattern }
        if (newState.daySegments.includes(shift)) {
            newState.daySegments = newState.daySegments.filter(e => e !== shift);
        } else {
            newState.daySegments.push(shift);
        }
        setPattern(newState);
    }

    return <Box>
        <Container style={{ "marginBottom": "4em" }}>
            <Flex justifyContent="space-between" style={{
                "marginTop": "1em",
                "gap": "1.5em 3em",
                "pointerEvents": readOnly ? "none": ""
            }}>
                <Box style={{ "flex": "2 0 20%"}}>
                    <Txt style={{ "marginBottom": "1em" }}
                        data-for="pattern-summary"
                        data-tip="Navngi en tilpasning">Kort beskrivelse</Txt>
                    <Tooltip id="pattern-summary" />
                    <Input value={pattern.summary} placeholder="Legg til beskrivelse" onChange={e => { pattern.summary = e.target.value; setPattern({ ...pattern }) }} />
                </Box>
                <Box style={{ "flex": "0 0 min-content" }}>
                    <Txt style={{ "marginBottom": "1em" }}
                        data-for="pattern-shifts"
                        data-tip="Velg hvilken vaktkategori tilpasningen skal omfatte for dagen(e) eller uken/perioden">Velg vakter</Txt>
                    <Tooltip id="pattern-shifts" />
                    <ButtonGroup>
                        <Button primary={pattern.daySegments.includes("D")} disabled={pattern.kind === "vacation"} onClick={() => toggleShift("D")}>Dag</Button>
                        <Button primary={pattern.daySegments.includes("A")} disabled={pattern.kind === "vacation"} onClick={() => toggleShift("A")}>Kveld</Button>
                        <Button primary={pattern.daySegments.includes("N")} disabled={pattern.kind === "vacation"} onClick={() => toggleShift("N")}>Natt</Button>
                        <Button primary={pattern.daySegments.includes("L")} disabled={pattern.kind === "vacation"} onClick={() => toggleShift("L")}>Lang</Button>
                    </ButtonGroup>
                    {error && ErrorMessage({ type: Object.hasOwn(error, "/day_segments") }, true, error["/day_segments"])}
                </Box>
            </Flex>
        </Container>
        <Container>
        <Box style={{
            "display": "grid",
            "gridTemplateColumns": "1fr 1.25fr .5fr 1.25fr .5fr",
            "alignItems": "center",
            "gap": "1em",
        }}>
            <Flex alignItems="center" style={{ gap: "1em", gridArea: "1 / 1 / 2 / 3", pointerEvents: readOnly ? "none": "" }}>
                <Box style={{ width: "min-content" }}>
                    <DatePicker
                        showWeekNumbers
                        disabled={pattern.kind === "vacation"}
                        locale="nb"
                        dateFormat="dd.MM.yyyy"
                        onChange={safeDatePickerOnChangeWrapper((date) => toggleDay(date))}
                        showPopperArrow={true}
                        popperPlacement="right"
                        portalId="root-portal"
                        shouldCloseOnSelect={false}
                        selected={selectedDate}
                        highlightDates={pattern.includeDays.map(d => new Date(d))}
                        customInput={
                            <Button
                                primary
                                disabled={pattern.kind === "vacation"}
                                data-for="pattern-day"
                                data-tip="Velg dato for tilpasning"
                            >Legg til dag</Button>
                        }
                    />
                </Box>
                <Tooltip id="pattern-day" />
                <Box
                    style={{ "marginLeft": "1em", "fontWeight": "500" }}
                    data-for="pattern-toggle"
                    data-tip="Ved ferie/fri over flere dager eller uker kan du legge til intervaller eller mønster her"
                >
                    <Checkbox
                        toggle
                        reverse
                        label="Legg til intervall/mønster"
                        checked={pattern.series.toggled || pattern.kind === "vacation"}
                        disabled={pattern.kind === "vacation"}
                        onChange={e => { pattern.series.toggled = e.target.checked; setPattern({ ...pattern }) }}
                    />
                </Box>
                <Tooltip id="pattern-toggle" />
                <Box
                    style={{ "marginLeft": "1em", "fontWeight": "500" }}
                    data-for="pattern-optimize"
                    data-tip="Tillat avvik avvik i turnusplanen"
                >
                    <Checkbox
                        toggle
                        reverse
                        label="Tillat avvik"
                        checked={pattern.optimize && pattern.kind === "notWorking"}
                        disabled={pattern.kind === "vacation"}
                        onChange={e => { pattern.optimize = e.target.checked; setPattern({ ...pattern }) }}
                    />
                </Box>
                <Tooltip id="pattern-optimize" />
            </Flex>
            <Box style={{ "gridArea": "3 / 1 / 5 / 2", "marginBottom": pattern.kind === "vacation" ? "1em" : 0,
                pointerEvents: readOnly ? "none": "" }}>
                <Collapsible open={pattern.series.toggled || pattern.kind === "vacation"}>
                    <Flex flexDirection="column" style={{ "gridArea": "3 / 1", "marginTop": "2em", "gap": "1em" }}>
                        <Box style={{
                            "display": "grid",
                            "gridTemplateColumns": "5em 8em",
                            "alignItems": "center",
                            "gap": "1em",
                        }}>
                            <Txt style={{ "width": "5em" }}>Fra</Txt>
                            <Box data-for="pattern-from" data-tip="Klikk på kalender ikonet for å sette startdato for periode med fri">
                                <DateInput
                                    style={{
                                        "color": pattern.kind === "vacation" && !pattern.series.from ? "red" : "unset",
                                    }}
                                    filterDate={(date) => pattern.kind !== "vacation" || date.getDay() === 1}
                                    value={pattern.kind !== "vacation" ? pattern.series.from : roundSeriesFrom(pattern.series.from)}
                                    maxDate={pattern.kind !== "vacation" && pattern.series.until && new Date(pattern.series.until)}
                                    onChange={date => {
                                        if (pattern.kind === "vacation") {
                                            const [from, until] = roundSeriesRange(pattern);
                                            if (from && until) {
                                                const weeks = Math.ceil(moment(until).diff(moment(from), 'd') / 7);
                                                pattern.series.until = moment(date)
                                                    .add(weeks, 'w')
                                                    .subtract(1, 'd')
                                                    .format("YYYY-MM-DD");
                                            }
                                        }
                                        pattern.series.from = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
                                        setPattern({ ...pattern })
                                    }} />
                                {error && <Box style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                                    {ErrorMessage({ type: Object.hasOwn(error, "/series/from") }, true, error["/series/from"])}
                                </Box>}
                                <Tooltip id="pattern-from" />
                            </Box>
                            <Txt style={{ "width": "5em" }}>{pattern.kind === "vacation" ? "Uker" : "Til"}</Txt>
                            {pattern.kind === "vacation"
                                ? <>
                                    <Input
                                        type="number"
                                        min={1}
                                        value={
                                            (() => {
                                                const [from, until] = roundSeriesRange(pattern);
                                                if (!from || !until) {
                                                    return '';
                                                }
                                                return Math.ceil(moment(until).diff(moment(from), 'd') / 7)
                                            })()
                                        }
                                        onChange={e => {
                                            const n = parseInt(e.target.value)
                                            const value = n > 0 ? n : 1;
                                            const from = roundSeriesRange(pattern)[0];
                                            pattern.series.until = moment(from)
                                                .add(value, 'w')
                                                .subtract(1, 'd')
                                                .format("YYYY-MM-DD");
                                            setPattern({ ...pattern });
                                        }
                                        } />
                                    {error && <Box style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                                        {ErrorMessage({ type: Object.hasOwn(error, "/series/until") }, true, error["/series/until"])}
                                    </Box>}
                                </>
                                : <Box data-for="pattern-until" data-tip="Klikk på kalender ikonet for å sette sluttdato for periode med fri">
                                    <DateInput
                                        style={{
                                            "color": pattern.kind === "vacation" && !pattern.series.until ? "red" : "unset",
                                        }}
                                        value={pattern.series.until}
                                        minDate={new Date(pattern.series.from)}
                                        onChange={date => { pattern.series.until = date; setPattern({ ...pattern }) }} />
                                    <Tooltip id="pattern-until" />
                                </Box>
                            }
                        </Box>
                    </Flex>
                </Collapsible>
            </Box>
            <Box style={{ "gridArea": "5 / 1 / 6 / 3", pointerEvents: readOnly ? "none": ""  }}>
                <Collapsible open={pattern.series.toggled && pattern.kind !== "vacation"}>
                    <Flex style={{ "gap": "1em", "alignItems": "center", "marginBottom": "2em" }}>
                        <Txt style={{ "width": "5em" }}>Hver</Txt>
                        <Flex style={{ "gap": "0.5em", "alignItems": "center" }}>
                            <span style={{ "width": "5em", "marginLeft": "0.5em", "marginRight": "0.5em" }}>
                                <Input
                                    type="number"
                                    min="1"
                                    defaultValue="1"
                                    value={pattern.series.nth}
                                    onChange={e => {
                                        let val = parseInt(e.target.value ? e.target.value : 1);
                                        pattern.series.nth = isNaN(val) ? 1 : val;
                                        setPattern({ ...pattern })
                                    }}
                                    data-for="pattern-repeat" data-tip="Dersom perioden med fri skal gjentas i andre uker velger du hvor ofte tilpasningen skal gjennomføres. 1= hver uke, 2= annenhver uke etc"
                                />
                                <Tooltip id="pattern-repeat" />
                            </span>
                        </Flex>
                    </Flex>
                </Collapsible>
            </Box>
            <Box style={{ "gridArea": "5 / 2 / 6 / 5" }}>
                <Collapsible open={pattern.series.toggled && pattern.kind !== "vacation"}>
                    <ButtonGroup style={{ "margin": "auto auto 2em auto" }}>
                        <Button
                            primary={pattern.series.mask[0]}
                            onClick={() => { pattern.series.mask[0] = !pattern.series.mask[0]; setPattern({ ...pattern }) }}
                        >Man</Button>
                        <Button
                            primary={pattern.series.mask[1]}
                            onClick={() => { pattern.series.mask[1] = !pattern.series.mask[1]; setPattern({ ...pattern }) }}
                        >Tir</Button>
                        <Button
                            primary={pattern.series.mask[2]}
                            onClick={() => { pattern.series.mask[2] = !pattern.series.mask[2]; setPattern({ ...pattern }) }}
                        >Ons</Button>
                        <Button
                            primary={pattern.series.mask[3]}
                            onClick={() => { pattern.series.mask[3] = !pattern.series.mask[3]; setPattern({ ...pattern }) }}
                        >Tor</Button>
                        <Button
                            primary={pattern.series.mask[4]}
                            onClick={() => { pattern.series.mask[4] = !pattern.series.mask[4]; setPattern({ ...pattern }) }}
                        >Fre</Button>
                        <Button
                            primary={pattern.series.mask[5]}
                            onClick={() => { pattern.series.mask[5] = !pattern.series.mask[5]; setPattern({ ...pattern }) }}
                        >Lør</Button>
                        <Button
                            primary={pattern.series.mask[6]}
                            onClick={() => { pattern.series.mask[6] = !pattern.series.mask[6]; setPattern({ ...pattern }) }}
                        >Søn</Button>
                    </ButtonGroup>
                    <div style={{ "paddingLeft": "130px" }}>
                        {error && ErrorMessage({ type: Object.hasOwn(error, "/series/mask") }, true, error["/series/mask"])}
                    </div>
                </Collapsible>
            </Box>
        </Box>
        </Container>
        <Container>
            <Box style={{
                "display": "grid",
                "gridTemplateColumns": "1fr 1.25fr .5fr 1.25fr .5fr",
                "alignItems": "center",
                "gap": "1em",
            }}>
                <Collapsible open={pattern.kind !== "vacation" && pattern.includeDays.length > 0}>
                    <Flex flexWrap="wrap" style={{ "gap": "1em", "alignItems": "center", "marginTop": "1em",
                        pointerEvents: readOnly ? "none": "" }}>
                        {
                            pattern.includeDays.map((d, i) => <DateSelection
                                key={i}
                                value={d}
                                onChange={(date) => updateDay(i, date)}
                                onClose={() => removeDay(i)}
                                excludeDates={pattern.includeDays.filter(e => e !== d).map(d => new Date(d))}
                            />)
                        }
                    </Flex>
                </Collapsible>
            </Box>
        </Container>
    </Box>
}
